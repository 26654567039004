import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  
  enableProdMode();
}

/*
// tslint:disable-next-line:no-shadowed-variable
function bootstrap(AppModule){
  console.log("BOOTSTRAP")
  return platformBrowserDynamic().bootstrapModule(AppModule)
    .then(moduleRef => {
      console.log('moduleRef: ', moduleRef);
      if (environment.hmr) {
        console.log("module", module['hot'])
        console.log('module: ', module);

        if (module['hot']) {
          module['hot']['accept']();
          if (moduleRef.instance['OnInit']) {
            if (module['hot']['data']) {
              moduleRef.instance['OnInit'](module['hot']['data']);
            }
          }
          if (moduleRef.instance['OnStatus']) {
            module['hot']['apply']((status) => {
              moduleRef.instance['OnStatus'](status);
            });
          }
          if (moduleRef.instance['OnCheck']) {
            module['hot']['check']((err, outdatedModules) => {
              moduleRef.instance['OnCheck'](err, outdatedModules);
            });
          }
          if (moduleRef.instance['OnDecline']) {
            module['hot']['decline']((dependencies) => {
              moduleRef.instance['OnDecline'](dependencies);
            });
          }

          module['hot']['dispose'](store => {
            if (moduleRef.instance['OnDestroy']) {
              moduleRef.instance['OnDestroy'](store);
            }
            moduleRef.destroy();
            if (moduleRef.instance['AfterDestroy']) {
              moduleRef.instance['AfterDestroy'](store);
            }
          });
        }
      }

      return moduleRef;
    });
}
*/
//bootstrap(AppModule);

/*
if (document.readyState === 'complete') {
  //bootstrap();
  bootstrap(AppModule);
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
*/

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => console.error(err));
};


if (document.readyState === 'complete') {
bootstrap();
} else {
document.addEventListener('DOMContentLoaded', bootstrap);
}
