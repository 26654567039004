import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, throwError } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'

import { AuthenticationService } from '../service/http/authentication.service'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  handleResponseError(error, request?, next?) {
    
    if (
      [401, 403].includes(error.status) &&
      this.authenticationService.userValue
    ) {
      return this.authenticationService.refreshAccessToken().pipe(
        switchMap(() => {
          
            return next.handle(request);
        }),
        catchError((e) => {
          if (e.status !== 401) {
            return this.handleResponseError(e);
          } else {

            this.authenticationService.logout(
            this.authenticationService.userValue
            );
          }
        })
      );
    } else if (error.status == 0 && request.url.includes("api.uretao.com")) {
      //forward to maintanence page

      this.router.navigate(['/offline']);
    }
    /* const errorw =
      (error && error.error && error.error.message) || error.statusText
    console.error(error);
    */
    return throwError(error);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {

    return next.handle(request).pipe(
      catchError((error) => {

        return this.handleResponseError(error, request, next);
      })
    );
  }
}
