import { NgModule } from '@angular/core';

import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import {
  person,
  arrowLeftCircle,
  circleFill,
  question,
  chevronUp,
  xCircleFill,
  plusCircleFill,
  patchMinusFill,
  patchPlusFill,
  printer,
  x,
  search,
  filterRight,
  listTask,
  columnsGap, 
  truck,
  chevronCompactRight,
  zoomIn,
  zoomOut,
  arrowClockwise,
  arrowCounterclockwise,
  crop,
  symmetryHorizontal,
  symmetryVertical,
  checkCircleFill,
  pencilFill,
  list,
  globe,
  shareFill,
  send,
  bell
} from 'ngx-bootstrap-icons';


// Select some icons (use an object, not an array)
const icons = {
  person,
  arrowLeftCircle,
  circleFill,
  question,
  chevronUp,
  xCircleFill,
  plusCircleFill,
  patchMinusFill,
  patchPlusFill,
  printer,
  x,
  search,
  filterRight,
  listTask,
  columnsGap,
  truck,
  chevronCompactRight,
  zoomIn,
  zoomOut,
  arrowClockwise,
  arrowCounterclockwise,
  crop,
  symmetryHorizontal,
  symmetryVertical,
  checkCircleFill,
  pencilFill,
  list,
  globe,
  shareFill,
  send,
  bell
};

@NgModule({
  imports: [NgxBootstrapIconsModule.pick(icons)],
  exports: [NgxBootstrapIconsModule],
})
export class IconsModule {}

// NOTES:
// 1. We add BootstrapIconsModule to the 'exports', since the <bi> component will be used in templates of parent module
// 2. Don't forget to pick some icons using BootstrapIconsModule.pick({ ... })
