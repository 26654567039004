<!-- does exist, login not required -->
<div fxShow.lt-sm fxHide>
  <div class="d-flex justify-content-end">
    <div>
      <a
        [matMenuTriggerFor]="lang"
        class="lead dropdown-toggle account header-text nav-item nav-link active"
        title="{{ selectedLang }}"
      >
        {{ selectedLang | uppercase }}
      </a>
  
      <mat-menu #lang="matMenu">
        <button
          *ngFor="let lang of languages"
          (click)="setTransLanguage(lang)"
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          title="{{ lang }}"
        >
          {{ lang | uppercase }}
        </button>
      </mat-menu>
    </div>
    <div>
      <a
        [matMenuTriggerFor]="account"
        class="lead dropdown-toggle account header-text nav-item nav-link active"
        title="Account"
      >
        <i-bs name="person" id="dropdownAccount" class="lead header-text"> </i-bs>
      </a>
  
      <mat-menu #account="matMenu">
        <button
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          routerLink="/login"
          title="Login"
        >
          {{ "header.login_dropdown.login" | translate }}
        </button>
        <button
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          routerLink="/register"
          title="Register"
        >
          {{ "header.login_dropdown.register" | translate }}
        </button>
      </mat-menu>
    </div>
  </div>
  </div>
  <!-- does exist, login not required -->
  <div fxShow fxHide.lt-sm>
    <div class="d-flex justify-content-end">
      <div>
        <a
          [matMenuTriggerFor]="lang"
          class="
            lead
            dropdown-toggle
            account
            header-text
            nav-item nav-link
            active
          "
          title="{{ selectedLang }}"
        >
          {{ selectedLang | uppercase }}
        </a>
  
        <mat-menu #lang="matMenu">
          <button
            *ngFor="let lang of languages"
            (click)="setTransLanguage(lang)"
            mat-menu-item
            class="lead header-text"
            style="outline: none"
            title="{{ lang }}"
          >
            {{ lang | uppercase }}
          </button>
        </mat-menu>
      </div>
      <div>
        <a
          class="lead account header-text nav-item nav-link active"
          title=" {{ 'header.login_dropdown.register' | translate }}"
          routerLink="/register"
        >
          {{ "header.login_dropdown.register" | translate }}
        </a>
      </div>
      <div>
        <a
          class="lead account header-text nav-item nav-link active"
          title=" {{ 'header.login_dropdown.login' | translate }}"
          routerLink="/login"
        >
          {{ "header.login_dropdown.login" | translate }}
        </a>
      </div>
    </div>
  </div>
  