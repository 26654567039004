import { isPlatformBrowser } from '@angular/common'
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { REQUEST } from '@nguniversal/express-engine/tokens'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { CookieService } from 'ngx-cookie-service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { Account } from './classes/account'
import { NavItem } from './interface/nav-item'
import { AuthenticationService } from './service/http/authentication.service'
import { NavService } from './service/nav.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  account: Account;
  newUser: Account;

  @ViewChild('appDrawer') appDrawer: ElementRef;
  mobile = [];
  navItems: NavItem[];
  navloggedInItems: NavItem[];

  constructor(
    public navService: NavService,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    //TODO add translation files per component, so not one global file
    //private _cookieBanner: MatBottomSheet,
    private translate: TranslateService,
    private router: Router,
    @Optional()
    @Inject(REQUEST)
    private request: Request,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    translate.addLangs(['de', 'en']);
    const lang = this.getLang();
    translate.use('en');

    translate.use(lang.match(/de|en/) ? lang : 'en');

    translate.get(['navItems', 'navloggedInItems']).subscribe((res: any) => {
      this.navItems = res.navItems;
      this.navloggedInItems = res.navloggedInItems;
    });
  }

  getLang(): string {
    let lang: string;
    if (isPlatformBrowser(this.platformId)) {
      lang = this.translate.getBrowserLang();
    } else {
      lang = (this.request.headers['accept-language'] || '').substring(0, 2);
    }
    return lang;
  }

  error;

  private ngUnsubscribe = new Subject();   
  ngOnInit() {
    /*
    const elements = document.getElementsByClassName('cdk-overlay-container');
    for (let i = 0; i < elements.length; i++) {
      elements[i].innerHTML = '';
    }
    */

    this.authenticationService.user
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((x) => (this.account = x));

    this.translate.onLangChange    .pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: LangChangeEvent) => {
      this.translate
        .get(['navItems', 'navloggedInItems'])
        .subscribe((res: any) => {
          this.navItems = res.navItems;
          this.navloggedInItems = res.navloggedInItems;
        });
    });

    //silent login
    this.silentLogin();

    //this.checkCookies();
  }

  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  silentLogin() {
    if (this.cookieService.get('user')) {
      this.authenticationService
        .silentLogin(this.cookieService.get('user'))
        .subscribe({
          next: (data) => {
            if (!this.authenticationService.userValue.verified) {
              this.router.navigate(['/verification']);
            }
          },
          error: (error) => {
            this.error = error;
            return false;
          },
        });
    }
  }
  ip: string 
  //TODO configure popup
  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }
}
