import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { Subject, takeUntil } from 'rxjs'

import { MetaTagService } from '../../service/meta-tag.service'

@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.scss']
})
export class CategorieComponent implements OnInit {

  constructor(
    private router: Router,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private metaService: MetaTagService
  ) {
    this.metaService.updateTags('categories');
  }

  baseUrlSell = 'auction/categories/sell/';
  baseUrlBuy = 'auction/categories/buy/';

  sell(url) {
    this.router.navigate([this.baseUrlSell + url]);
  }

  onDemo() {
    this.openSnackBar('Site is in presentation mode');
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 4 * 1000 });
  }

  buy(url) {
    this.router.navigate([this.baseUrlBuy + url]);
  }

  private ngUnsubscribe = new Subject();
  ngOnInit() {
    this.translate.get(['categories']).subscribe((res: any) => {
      this.categories = res.categories;
    });


    this.translate.onLangChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((language: LangChangeEvent) => {
        this.metaService.updateTags('categories');
      });
  }

  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  categories;
}
