export const environment = {
  production: true,
  apiUrl: 'https://www.api.uretao.com',
  domain: 'uretao.com',
  hostUrl: 'https://www.uretao.com/',
  distFolderPath: '../browser',
  imageFolderPath: '../browser/auctions',
  assetFolderPath: '../browser/assets',
  hmr: true,
  base: 'https://www.uretao.com/auctions/',
  base_archived: 'https://www.uretao.com/auctions/archive/'
};
