import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { retry } from 'rxjs/operators'

import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class CookieUretaoService {
  constructor(private http: HttpClient) {}

  //create either by
  //1. IP address
  //if exists: 2. account.id
  create(consent) {
    //add correct return, after creation update habit list

    return this.http
      .post<any>(
        `${environment.apiUrl}/consents/create`,
        { consent },
        {
          withCredentials: true,
        }
      )
  }

  revoke(requester) {
    //add correct return, after creation update habit list

    return this.http
      .post<any>(
        `${environment.apiUrl}/consents/revoke`,
        { requester },
        {
          withCredentials: true,
        }
      )
  }

  check(requester) {
    //add correct return, after creation update habit list

    
    return this.http
      .post<any>(
        `${environment.apiUrl}/consents/check`,
        requester
        ,
        {
          withCredentials: true,
        }
      )
      .pipe(retry(2));
  }


  //update partial if user disables specific cookies
  //1. IP address
  //if exists: 2. account.id
  update(consent) {
    //add correct return, after creation update habit list
    return this.http
      .put<any>(
        `${environment.apiUrl}/consents/create`,
        { consent },
        {
          withCredentials: true,
        }
      )
      .pipe(retry(2));
  }
}
