<div
  fxLayoutGap.lt-sm="0px"
  fxLayoutAlign="space-between start"
  fxLayout="row"
  class="mt-2 mb-2"
  fxHide.lt-sm
  fxShow
>
  <div fxFlex class="image-space-left">
    <!-- Button for collapse -->
    <button
      class="small-screen-menu navbar-toggler"
      type="button"
      fxHide
      fxShow.lt-sm
      (click)="navService.openNav()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <a
      class="small-screen pl-5-lg d-flex mr-auto-lg"
      routerLink="/"
      title="Home"
    >
      <img
        class="logo-size img-fluid"
        src="/assets/img/logos/logo_small.jpg"
        alt="Logo smaller white"
        title="Logo Kevin Isenberg"
        loading="lazy"
      />
    </a>
  </div>

  <div fxFlex fxLayout="column">
    <div fxFlex fxLayout="row">
      <!-- does exist, login not required -->
      <ng-container *ngIf="this.account">
        <div fxFlex>
          <app-header-logged-in></app-header-logged-in>
        </div>
      </ng-container>

      <!-- does not exist, login required -->
      <ng-container *ngIf="!this.account">
        <div fxFlex>
          <app-header-not-logged-in></app-header-not-logged-in>
        </div>
      </ng-container>
    </div>
    <div fxFlex>
      <app-search></app-search>
    </div>
  </div>

  <div fxFlex class="right-space">
    <!-- does exist, login not required -->
    <ng-container *ngIf="this.account">
      <app-account-header></app-account-header>
    </ng-container>

    <!-- does not exist, login required -->
    <ng-container *ngIf="!this.account">
      <app-account-not-logged-in></app-account-not-logged-in>
    </ng-container>
  </div>
</div>
<div fxLayoutGap.lt-sm="0px" fxLayout="column" class="mt-2 mb-2" fxShow.lt-sm fxHide>
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="space-between start"
    class=""
  >
    <!-- Button for collapse -->
    <div fxFlex class="mobile-space-top">
      <button
        fxHide
        fxShow.lt-sm
        mat-button
        class=""
        type="button"
        (click)="navService.openNav()"
      >
      <i-bs name="list" width="40" height="40" class="highlight pl-2"> </i-bs>
      </button>
    </div>
    <div fxFlex>
      <a
        class="pl-5-lg d-flex mr-auto-lg"
        routerLink="/"
        title="Home"
      >
        <img
          class="logo-size img-fluid"
          src="/assets/img/logos/logo_small.jpg"
          alt="Logo smaller white"
          title="Logo Kevin Isenberg"
        />
      </a>
    </div>
    <div>
      <!-- does exist, login not required -->
      <ng-container *ngIf="this.account">
        <app-account-header></app-account-header>
      </ng-container>

      <!-- does not exist, login required -->
      <ng-container *ngIf="!this.account">
        <app-account-not-logged-in></app-account-not-logged-in>
      </ng-container>
    </div>
  </div>
  <div fxFlex>
    <app-search-mobile></app-search-mobile>
  </div>
</div>
