import { CommonModule, DatePipe } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogRef } from '@angular/material/dialog'
import { MatListModule } from '@angular/material/list'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { BrowserModule, HammerModule, TransferState } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { TransferHttpCacheModule } from '@nguniversal/common'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons'
import { PinchZoomModule } from 'ngx-pinch-zoom'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CookieBannerModule } from './cookies/cookie-banner/cookie-banner.module'
import { ErrorInterceptor } from './helpers/error.interceptor'
import { HoldableDirective } from './helpers/holdable.directive'
import { IconsModule } from './icons/icons.module'
import { FooterModule } from './layout/footer/footer.module'
import { HeaderModule } from './layout/header/header.module'
import { MenuListItemComponent } from './layout/sidenav/menu-list-item/menu-list-item.component'
import { SidenavModule } from './layout/sidenav/sidenav.module'
import { translateBrowserLoaderFactory } from './loaders/translate-browser.loader'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HoldableDirective,
    MenuListItemComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FlexLayoutModule,
    //OverlayModule,
    HttpClientModule,
    // check if needed NgbModule,
    BrowserAnimationsModule,
    //TagInputModule,

    //TextFieldModule,
    HammerModule,
    TransferHttpCacheModule,
    NgxBootstrapIconsModule,
    //ClipboardModule,
    MatSidenavModule,
    //import header, footer, sidenav
    HeaderModule,
    FooterModule,
    SidenavModule,
    CookieBannerModule,

    RouterModule,

    //sidenav
    MatListModule,
    IconsModule,

    //MatNativeDateModule,

    //ScrollingModule,
    //NgScrollbarModule,

   // InfiniteScrollModule,
    //MatInputAutosizeModule,

    // MatTabsModule,
    //  MatCheckboxModule,
    //  ImageCropperModule,
    MatSnackBarModule, //nullinjection error if removed
    //   MatSlideToggleModule,
    //MatFormFieldModule,
    PinchZoomModule, //nullinjection error if removed

    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
  ],
  providers: [
    DatePipe,
    //{ provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService, CookieService] },
    //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

//leads to circular dependency :/
//platformBrowserDynamic().bootstrapModule(AppModule);
