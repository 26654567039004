import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../service/http/authentication.service';
import { Account } from '../../../classes/account';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-account-not-logged-in',
  templateUrl: './account-not-logged-in.component.html',
  styleUrls: ['./account-not-logged-in.component.scss']
})
export class AccountNotLoggedInComponent implements OnInit, OnDestroy {
  constructor(
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private router: Router,
    @Optional()
    @Inject(REQUEST) private request: Request,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  languages;
  selectedLang;
  loggedIn: false;
  account: Account;

  logout() {
    if (this.account) {
      this.authenticationService.logout(this.account).subscribe({
        next: (data) => {

          this.router.navigate(['/']);
        },
        error: (error) => {
 
        },
      });
    }
  }

  setTransLanguage(lang) {
    this.translate.use(lang);
    this.translate.setDefaultLang(lang);
    this.getSelectedLang(lang);
  }

  getLanguages() {
    this.languages = this.translate.getLangs();
  }

  getSelectedLang(lang) {
    this.selectedLang = lang
  }

 getLang(): string {
    let lang: string;
    if (isPlatformBrowser(this.platformId)) {
      lang = this.translate.getBrowserLang();
    } else {
      lang = (this.request.headers['accept-language'] || '').substring(0, 2);
    }
    return lang;
  }

  getBrowserLang() {
    //this.selectedLang = this.translate.getBrowserLang();
    this.selectedLang = this.getLang()
    this.translate.use(this.selectedLang.match(/de|en/) ? this.selectedLang : 'en');
  }

  private ngUnsubscribe = new Subject();   
  ngOnInit() {
    this.authenticationService.user
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((x) => (this.account = x));


    this.getBrowserLang();
    this.getLanguages();
  }

  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
