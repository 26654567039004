import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { CookieService } from 'ngx-cookie-service'
import { concatMap } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { Account } from '../../classes/account'
import { CookieUretaoService } from '../../service/http/cookie-uretao.service'
import { IpService } from '../../service/http/ip.service'

@Component({
  selector: 'app-cookie-dialog',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss']
})
export class CookieDialogComponent implements OnInit {

  constructor(
  private cookieService: CookieService,
  private ipService: IpService,
  private cookieUretaoService: CookieUretaoService,
  public dialogRef: MatDialogRef<CookieDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    this.account = data.account
    this.ip = data.ip

  }

  account: Account
  ip: string

  ngOnInit(){

  }

  setCookiesForCookieBanner(all: string, settings: string) {
    this.cookieService.set(
      'cookie-all',
      all,
      365,
      '/',
      environment.domain,
      false
    );
    this.cookieService.set(
      'cookie-settings',
      settings,
      365,
      '/',
      environment.domain,
      true
    );


  }

  acceptAll() {

    //accept all and set settings to false
    this.setCookiesForCookieBanner('true', 'false');
    //aa = accept all
    this.create("aa")
  }

  create(consentInput){

    console.log('this.dialogRef before: ', this.dialogRef);

    this.ipService
      .getIP()
      .pipe(
        concatMap((ipAddress) => {
          
          let consent = {
            ip_address: ipAddress.ip,
            //c = cookie banner
            type: 'c',
            consent: consentInput
          };

          //check if user is logged in
 
            
          if (this.account){
            consent["user_id"] = this.account.id
          }

          return this.cookieUretaoService.create(consent);
        })
      )
      .subscribe({
        next: () => {

          console.log('thisssu: ', this.dialogRef);
   
          this.close();
        },
        error: (error) => {
     
        },
      });
  }

  reject() {
    //reject, set false for both cookies
    this.setCookiesForCookieBanner('false', 'false');
    //r = reject all
    this.create("ra")
  }


  close() {
    console.log("CLOSE")
    console.log('this.dialogRef: ', this.dialogRef);
    //close current bottom sheet
    //this._bottomSheetRef.dismiss()
    this.dialogRef.close()
    
  }


  openSettings() {
    this.close();
    //open new bottom sheet

    //set cookies
    //accept all false; settings true
    this.setCookiesForCookieBanner('false', 'true');

    /*
    //open settings
    this._cookieBannerSettings.open(CookieSettingsComponent, {
      autoFocus: false,
      hasBackdrop: false,
      data: {
        account: this.account
      },
    });
    */
  }


}
