import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatDialogModule } from '@angular/material/dialog'
import { MatListModule } from '@angular/material/list'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateModule } from '@ngx-translate/core'

import { CookieSettingsComponent } from '../cookie-settings/cookie-settings.component'
import { CookieDialogComponent } from './cookie-dialog.component'



@NgModule({
  declarations: [CookieDialogComponent, CookieSettingsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatListModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDialogModule
  ]
})
export class CookieDialogModule { }
