import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatListModule } from '@angular/material/list'
import { MatSidenavModule } from '@angular/material/sidenav'
import { TranslateModule } from '@ngx-translate/core'

import { IconsModule } from '../../icons/icons.module'
import { SidenavComponent } from './sidenav.component'

@NgModule({
  declarations: [SidenavComponent ],
  imports: [CommonModule, MatListModule, IconsModule, TranslateModule, MatSidenavModule],
  exports: [SidenavComponent],
})
export class SidenavModule {
}
