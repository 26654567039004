<a
  mat-list-item
  [ngStyle]="{ 'padding-left': depth * 12 + 'px' }"
  (click)="onItemSelected(item)"
  [ngClass]="{
    active: item.route ? router.isActive(item.route, true) : false,
    expanded: expanded
  }"
  class="lead header-text menu-list-item"
>
  {{ item.displayName }}
  <span fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <i-bs
      class="text-center routeIcon"
      name="chevron-up"
      [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'"
    >
    </i-bs>
  </span>
</a>
<div *ngIf="expanded">
  <app-menu-list-item
    *ngFor="let child of item.children"
    [item]="child"
    [depth]="depth + 1"
  >
  </app-menu-list-item>
</div>
