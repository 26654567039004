<mat-sidenav-container [hasBackdrop]="false" class="h-100 bg-white">
    <mat-sidenav class="w-100" #appDrawer mode="over" opened="false">
        <mat-nav-list class="container-lg">
          <div class="d-flex justify-content-between mb-3">
            <div class="align-self-center">
              <i-bs
                name="x-circle-fill"
                style="color: #494d70; opacity: 50%"
                width="2rem"
                height="2rem"
                (click)="navService.closeNav()"
              >
              </i-bs>
            </div>
            <a routerLink="/" title="Home">
              <img
                class="logo-size img-fluid"
                src="/assets/img/logos/logo_small.jpg"
                alt="Logo smaller white"
                title="Logo Kevin Isenberg"
                loading="lazy"
                width="113px"
                height="100px"
              />
            </a>
            <!-- Empty div for flexbox -->
            <div></div>
            <!-- 
              <div class="ml-auto">
                <div class="row">
                  <app-account></app-account>
                </div>
              </div>
            -->
          </div>
          <!-- TODO add header login -->
      
          <!-- does exist, login not required -->
          <ng-container *ngIf="this.account">
            <app-menu-list-item
              *ngFor="let item of navItems"
              [item]="item"
            ></app-menu-list-item>
          </ng-container>
      
          <!-- does not exist, login required -->
          <ng-container *ngIf="!this.account">
            <app-menu-list-item
              *ngFor="let item of navloggedInItems"
              [item]="item"
            ></app-menu-list-item>
          </ng-container>
        </mat-nav-list>
      </mat-sidenav>      
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
  <app-cookie-banner></app-cookie-banner>
</mat-sidenav-container>
