import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'

import { Account } from '../../classes/account'

@Component({
  selector: 'app-search-desktop',
  templateUrl: './search-desktop.component.html',
  styleUrls: ['./search-desktop.component.scss']
})
export class SearchDesktopComponent implements OnInit {

  constructor(
    private router: Router,) {}

  ngOnInit(): void {}

  //TODO add filter for not allowed letters
  searchControl = new FormControl('');
  form: FormGroup;
  account: Account;
  error: '';
  totalRecords: number = 0;
  id: number;
  start: number;
  grid_length: number;
  width: boolean = false;
  perPage: number = 10;
  p: number = 1;
  sorted: boolean = false;
  sortedOption;
  totalCount: number = 0;
  crucibles;
  decisionSearch = 'Search';
  disableSearch: boolean = false;
  loading: boolean = false;

  search() {

    if (this.searchControl.value){
   
      let search = encodeURIComponent(this.searchControl.value);
    
      let link = '/search/' + search
      this.router.navigate([link]);
    } else {
      //error snackbar enter something
    }

  }
}
