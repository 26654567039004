<mat-nav-list class="mx-2 my-2">
  <div>
    {{ "cookie.message1" | translate
    }}<a routerLink="/cookie-policy" class="highlight">
      {{ "cookie.cookie_policy" | translate }}</a
    >{{ "cookie.message2" | translate }}
  </div>
  <!-- TODO highlight URLs -->
  <br />
  <div>{{ "cookie.demo" | translate }}</div>
  <br />
  <div>
    {{ "cookie.learn" | translate
    }}<a routerLink="/privacy" class="highlight">{{
      "cookie.policy" | translate
    }}</a>
    {{ "cookie.and" | translate
    }}<a routerLink="/termsofuse" class="highlight">{{
      "cookie.tos" | translate
    }}</a
    >{{ "cookie.point" | translate }}
  </div>
  <br />
  <!-- Grid for spacing -->
  <div fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayout="column">
    <div fxFlex="100">
      <button
        type="submit"
        (click)="reject()"
        class="button-sm button btn w-100"
      >
        {{ "cookie.reject" | translate }}
      </button>
    </div>
    <div fxFlex="100">
      <button
        fxFlex="100"
        type="submit"
        (click)="acceptAll()"
        class="button-sm button btn w-100"
      >
        {{ "cookie.accept_all" | translate }}
      </button>
    </div>
    <div fxFlex="100">
      <button
        fxFlex="100"
        type="submit"
        (click)="openSettings()"
        class="button-sm button btn w-100"
      >
        {{ "cookie.settings" | translate }}
      </button>
    </div>
  </div>
</mat-nav-list>
