<form class="w-100" (ngSubmit)="search()">
  <mat-form-field class="w-100" appearance="standard">
    <input
      matInput
      type="text"
      id="price"
      [formControl]="searchControl"
      name="price"
    />
    <i-bs
      matSuffix
      name="search"
      class="text-primary"
      width="1rem"
      height="1rem"
    >
    </i-bs>
  </mat-form-field>
</form>