import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private http: HttpClient) {}

  private conversationsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public conversations: Observable<any> =
    this.conversationsSubject.asObservable();

  private notificationsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public notifications: Observable<any> =
    this.notificationsSubject.asObservable();

  private chatSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public chat: Observable<any> = this.chatSubject.asObservable();

  //get conversations --> handle empty conversations
  getConversations(id: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/chat/conversations`, {
        withCredentials: true,
        params: {
          id: id.toString(),
        },
      })
      .pipe(
        map((conversations) => {
          //truncuate conversation body
          conversations = this.truncateMessages(conversations);
          this.conversationsSubject.next(conversations);
          return conversations;
        })
      );
  }

  //get conversations --> handle empty conversations
  getNotifications(id: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/chat/notifications`, {
        withCredentials: true,
        params: {
          id: id.toString(),
        },
      })
      .pipe(
        map((notifications) => {
          //truncuate conversation body
          this.notificationsSubject.next(notifications);
          return notifications;
        })
      );
  }

  //get conversations --> handle empty conversations
  readMessage(user_id: number, conversation_id) {
    let object = {
      user_id: user_id,
      conversation_id: conversation_id,
    };
    return this.http.post<any>(`${environment.apiUrl}/chat/read`, object, {
      withCredentials: true,
    });
  }

  truncateMessages(conversations) {
    conversations.forEach((conversation) => {
      conversation.body_short = conversation.body.substring(0, 100);
    });
    return conversations;
  }

  getChat(conversation_id: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/chat`, {
        withCredentials: true,
        params: {
          conversation_id: conversation_id.toString(),
        },
      })
      .pipe(
        map((chat) => {
          //truncuate conversation body
          this.chatSubject.next(chat);
          return chat;
        })
      );
  }

  create(id: number, message: string, recipient_id: number) {
    let messageObject = {
      id: id,
      message: message,
      recipient_id: recipient_id,
    };

    return this.http.put<any>(
      `${environment.apiUrl}/chat/create`,
      messageObject,
      {
        withCredentials: true,
      }
    );
  }

  //update or insert new message
}
