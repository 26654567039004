import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatBadgeModule } from '@angular/material/badge'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatMenuModule } from '@angular/material/menu'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'

import { IconsModule } from '../../icons/icons.module'
import { SearchModule } from '../../search/search.module'
import { AccountNotLoggedInComponent } from './account-not-logged-in/account-not-logged-in.component'
import { AccountHeaderComponent } from './account/account-header.component'
import { HeaderLoggedInComponent } from './header-logged-in/header-logged-in.component'
import { HeaderNotLoggedInComponent } from './header-not-logged-in/header-not-logged-in.component'
import { HeaderComponent } from './header.component'

@NgModule({
  declarations: [HeaderComponent, AccountHeaderComponent, AccountNotLoggedInComponent, 
  HeaderLoggedInComponent, HeaderNotLoggedInComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    IconsModule,
    TranslateModule,
    MatButtonModule,
    MatButtonToggleModule,
    FlexLayoutModule,
    MatBadgeModule,
    SearchModule,
    RouterModule
  ],
  exports: [HeaderComponent, AccountHeaderComponent, AccountNotLoggedInComponent, 
    HeaderLoggedInComponent, HeaderNotLoggedInComponent]
})
export class HeaderModule { }
