import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { TranslateModule } from '@ngx-translate/core'

import { IconsModule } from '../icons/icons.module'
import { SearchDesktopComponent } from './search-desktop/search-desktop.component'
import { SearchMobileComponent } from './search-mobile/search-mobile.component'
import { SearchComponent } from './search.component'



@NgModule({
  declarations: [
    SearchComponent,
    SearchDesktopComponent,
    SearchMobileComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatInputModule,
    FlexLayoutModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    IconsModule
  ],
  exports:[
    SearchComponent,
    SearchDesktopComponent,
    SearchMobileComponent
  ]
})
export class SearchModule { }
