<div class="content" role="main">
    <div class="container">
      <ng-container *ngIf="categories">
        <!-- Desktop -->
        <div fxHide.lt-sm fxHide.lt-lg fxShow >
          <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="10px">
            <div fxFlex="49" (click)="buy(categorie.value)" *ngFor="let categorie of categories; let i = index">
              <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title>{{ categorie.name }}</mat-card-title>
                  <mat-card-subtitle>{{ categorie.subtitle }}</mat-card-subtitle>
                </mat-card-header>
                <img
                  mat-card-image
                  class="image-desktop"
                  src="{{ categorie.url }}"
                  alt="{{ categorie.alt }}"
                  loading="{{ categorie.lazy }}"
                />
              </mat-card>
            </div>
          </div>
        </div>
        <!-- Mobile -->
        <div fxHide fxShow.lt-sm >
          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.lt-sm="column" fxLayoutGap="10px">
            <div fxFlex="49" (click)="buy(categorie.value)" *ngFor="let categorie of categories; let i = index">
              <mat-card class="example-card-mobile">
                <mat-card-header>
                  <mat-card-title class="">{{ categorie.name }}</mat-card-title>
                  <mat-card-subtitle class="">{{ categorie.subtitle }}</mat-card-subtitle>
                </mat-card-header>
                <img
                  mat-card-image
                  class="mobile-image"
                  src="{{ categorie.url }}"
                  alt="{{ categorie.alt }}"
                  loading="lazy"
                />
              </mat-card>
            </div>
          </div>
        </div>
        <!-- Tablet / Large -->
        <div fxHide fxHide.lt-sm fxShow.lt-lg>
          <div fxLayout="row wrap" fxLayoutGap="10px" >
            <div fxFlex="48" (click)="buy(categorie.value)" *ngFor="let categorie of categories; let i = index">
              <mat-card class="example-card-tablet mat-elevation-z0" >
                <mat-card-header>
                  <mat-card-title>{{ categorie.name }}</mat-card-title>
                  <mat-card-subtitle>{{ categorie.subtitle }}</mat-card-subtitle>
                </mat-card-header>
                <img
                  mat-card-image
                  src="{{ categorie.url }}"
                  alt="{{ categorie.alt }}"
                  loading="lazy"
                  (click)="buy(categorie.value)"
                />
              </mat-card>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  