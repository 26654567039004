import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieBannerComponent } from './cookie-banner.component';
import { CookieDialogModule } from '../cookie-dialog/cookie-dialog.module';



@NgModule({
  declarations: [CookieBannerComponent],
  imports: [
    CommonModule,
    CookieDialogModule
  ],
  exports: [CookieBannerComponent]
})
export class CookieBannerModule { }
