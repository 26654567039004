<!-- does not exist, login required -->
<div fxShow fxHide.lt-sm>
  <div class="d-flex justify-content-end">
    <!-- Notifications -->
    <div>
      <ng-container *ngIf="amountOfNotifications">
        <a
          [matMenuTriggerFor]="notifications"
          class="mobile-space-top lead account header-text nav-item nav-link active"
          title="Notifications"
        >
          <i-bs
            name="bell"
            id="dropdownAccount"
            class="lead header-text"
            matBadgeColor="warn"
            matBadge="{{ amountOfNotifications }}"
            [matBadgeOverlap]="false"
          >
          </i-bs>
        </a>

        <mat-menu #notifications="matMenu" backdropClass="customize">
          <button
            *ngFor="let notification of notificationsArray"
            mat-menu-item
            (click)="openMessage(notification)"
            class="w-100 menu-item-height"
            style="outline: none"
            title="Notification from {{ notification.username }} "
          >
            {{ notification.username }} has sent you a unread message
          </button>
        </mat-menu>
      </ng-container>
      <ng-container *ngIf="!amountOfNotifications">
        <a
          class="mobile-space-top lead account header-text nav-item nav-link active"
          title="Notifications"
        >
          <i-bs
            name="bell"
            id="dropdownAccount"
            class="lead header-text"
          >
          </i-bs>
        </a>
      </ng-container>
    </div>
    <!-- Lang -->
    <div>
      <a
        [matMenuTriggerFor]="lang"
        class="lead dropdown-toggle account header-text nav-item nav-link active"
        title="{{ selectedLang }}"
      >
        {{ selectedLang | uppercase }}
      </a>

      <mat-menu #lang="matMenu">
        <button
          *ngFor="let lang of languages"
          (click)="setTransLanguage(lang)"
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          title="{{ lang }}"
        >
          {{ lang | uppercase }}
        </button>
      </mat-menu>
    </div>
    <!-- Account -->
    <div>
      <a
        [matMenuTriggerFor]="account"
        class="lead dropdown-toggle account header-text nav-item nav-link active"
        title="Account"
      >
        <i-bs name="person" id="dropdownAccount" class="lead header-text">
        </i-bs>
      </a>

      <mat-menu #account="matMenu">
        <button
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          routerLink="/account"
          title="Account"
        >
          {{ "header.login_dropdown.account" | translate }}
        </button>
        <button
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          routerLink="/account/chat"
          title="Contact"
        >
          {{ "header.login_dropdown.messages" | translate }}
        </button>
        <button
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          routerLink="/account/contact"
          title="Contact"
        >
          {{ "header.login_dropdown.contact" | translate }}
        </button>
        <button
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          title="Logout"
          (click)="logout()"
        >
          {{ "header.login_dropdown.logout" | translate }}
        </button>
      </mat-menu>
    </div>
  </div>
</div>
<div fxHide fxShow.lt-sm>
  <div class="d-flex justify-content-end">
        <!-- Notifications -->
        <div class="margin-mobile-bell">
          <ng-container *ngIf="amountOfNotifications">
            <a
              [matMenuTriggerFor]="notifications"
              class="mobile-space-top lead account header-text nav-item nav-link active"
              title="Notifications"
            >
              <i-bs
                name="bell"
                id="dropdownAccount"
                class="lead header-text"
                matBadgeColor="warn"
                MatBadgeSize="small"
                matBadge="{{ amountOfNotifications }}"
                [matBadgeOverlap]="false"
              >
              </i-bs>
            </a>
    
            <mat-menu #notifications="matMenu" backdropClass="customize">
              <button
                *ngFor="let notification of notificationsArray"
                mat-menu-item
                (click)="openMessageMobile(notification)"
                class="w-100 menu-item-height"
                style="outline: none"
                title="Notification from {{ notification.username }} "
              >
                {{ notification.username }} has sent you a unread message
              </button>
            </mat-menu>
          </ng-container>
          <ng-container *ngIf="!amountOfNotifications">
            <a
              class="mobile-space-top lead account header-text nav-item nav-link active"
              title="Notifications"
            >
              <i-bs
                name="bell"
                id="dropdownAccount"
                class="lead header-text"
              >
              </i-bs>
            </a>
          </ng-container>
        </div>
    <div>
      <a
        [matMenuTriggerFor]="lang"
        class="mobile-space-top lead dropdown-toggle account header-text nav-item nav-link active"
        title="{{ selectedLang }}"
      >
        {{ selectedLang | uppercase }}
      </a>

      <mat-menu #lang="matMenu">
        <button
          *ngFor="let lang of languages"
          (click)="setTransLanguage(lang)"
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          title="{{ lang }}"
        >
          {{ lang | uppercase }}
        </button>
      </mat-menu>
    </div>
    <div>
      <a
        [matMenuTriggerFor]="account"
        class="mobile-space-top lead dropdown-toggle account header-text nav-item nav-link active"
        title="Account"
      >
        <i-bs name="person" id="dropdownAccount" class="lead header-text">
        </i-bs>
      </a>

      <mat-menu #account="matMenu">
        <button
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          routerLink="/account"
          title="Account"
        >
          {{ "header.login_dropdown.account" | translate }}
        </button>
        <button
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          routerLink="/account/contact"
          title="Contact"
        >
          {{ "header.login_dropdown.contact" | translate }}
        </button>
        <button
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          title="Logout"
          (click)="logout()"
        >
          {{ "header.login_dropdown.logout" | translate }}
        </button>
      </mat-menu>
    </div>
  </div>
</div>
