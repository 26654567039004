import { Component, Inject, NgZone, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { REQUEST } from '@nguniversal/express-engine/tokens'
import { CookieService } from 'ngx-cookie-service'
import { concatMap, take } from 'rxjs'

import { Account } from '../../classes/account'
import { CookieUretaoService } from '../../service/http/cookie-uretao.service'
import { IpService } from '../../service/http/ip.service'
import { CookieDialogComponent } from '../cookie-dialog/cookie-dialog.component'


@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements OnInit, OnDestroy {
  constructor(
    private cookieService: CookieService,
    //TODO add translation files per component, so not one global file
    //private _cookieBanner: MatBottomSheet,
    private ipService: IpService,
    private cookieUretaoService: CookieUretaoService,
    private router: Router,
    public dialog: MatDialog,
    private zone: NgZone,
    @Optional()
    @Inject(REQUEST)
    private request: Request,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  account: Account;
  ip: String;

  ngOnInit() {
    this.checkCookies();
  }

  checkCookies() {
    this.nukeDialog();

    let allCookies = this.cookieService.get('cookie-all');
    let cookieSettings = this.cookieService.get('cookie-all');

    if (this.checkIfEmpty(allCookies)) {
      //1. get IP address
      this.ipService
        .getIP()
        .pipe(
          take(1),
          concatMap((ip_address) => {
      

            let requester = {
              ip_address: ip_address.ip,
            };

            this.ip = ip_address.ip;

            //check if user is logged in
            if (this.account) {
              requester['user_id'] = this.account.id;
            }



            return this.cookieUretaoService.check(requester);
          })
        )
        .pipe(take(1))
        .subscribe({
          next: (res) => {
  
            if (res.code == 0) {

              this.openDialog();
              //          this.openCookieBanner();
            } else {
              //TODO use cookie settings
              //cookie exists, use the setting
            }
          },
          error: (error) => {
            /*
            if (error.status == 0) {

              this.openDialog();
              //          this.openCookieBanner();
            } else {
              //TODO use cookie settings
              //cookie exists, use the setting
            }
            */
          },
        });
    } else {
      //nuke dialog
      this.nukeDialog();

      //check if what value exists
      if (allCookies === 'false') {
        //check if settings
        this.checkSettings(cookieSettings);
      } else {
        //TODO accept all cokies
      }
    }
  }

  nukeDialog() {

    if (document) {
      const elements = document.getElementsByClassName('cdk-overlay-container');
      for (let i = 0; i < elements.length; i++) {
        elements[i].innerHTML = '';
      }
    }
  }

  openDialog() {


    this.zone.run(() => {
      const dialogRef = this.dialog.open(CookieDialogComponent, {
        autoFocus: false,
        hasBackdrop: false,
        disableClose: true,
        panelClass: 'custom-width',
        data: {
          account: this.account,
          ip: this.ip,
        },
      });
      //dialogRef.close()
      this.router.events.subscribe(() => {
        dialogRef.close();
      });
    });
  }

  //TODO destroy popup
  // unsubscribe to cookieconsent observables to prevent memory leaks
  ngOnDestroy() {
    this.dialog.closeAll();
  }

  //check if cookies are empty
  checkIfEmpty(cookie) {


    if (cookie) {

      return false;
    } else {

      return true;
    }
  }

  checkSettings(cookieSettings) {
    if (cookieSettings === 'true') {
      //TODO check each cookie and enable them
    } else {
      //TODO reject all cookies
    }
  }
}
