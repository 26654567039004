import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subject, takeUntil } from 'rxjs'

import { Account } from '../../classes/account'
import { AuthenticationService } from '../../service/http/authentication.service'
import { NavService } from '../../service/nav.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  constructor(
    private authenticationService: AuthenticationService,
    public navService: NavService
  ) {
   }

  account: Account;

  private ngUnsubscribe = new Subject();   
  ngOnInit(){
    this.authenticationService.user
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((x) => (this.account = x));


  }

  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

}
