import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MetaTagService {
  constructor(
    private title: Title,
    private meta: Meta,
    private translate: TranslateService
  ) {}

  //in component on lang change
  //init in ngOnInit
  addTags(sitename, lang) {
    sitename = 'seo.' + sitename;
    let keys = [
      sitename + '.title',
      sitename + '.description',
      sitename + '.type',
      sitename + '.url',
      sitename + '.image',
      sitename + '.card',
      sitename + '.keywords',
    ];
 
    this.translate.get(keys).subscribe((res: any) => {

      //general
      this.meta.addTag({ name: 'author', content: 'Uretao' });
      //TODO update sitemap url
      this.meta.addTag({
        name: 'sitemap',
        type: 'application/xml',
        content: 'https://uretao.com/sitemap.xml',
      });
      this.meta.addTag({ name: 'googlebot', content: 'index/follow' });
      this.meta.addTag({ name: 'theme-color', content: '#369ad5' });
      this.meta.addTag({
        name: 'msapplication-navbutton-color',
        content: '#d66b40',
      });
      this.meta.addTag({
        name: 'apple-mobile-web-app-status-bar-style',
        content: '#d66b40',
      });
      this.meta.addTag({ name: 'og:determiner', content: 'Uretao' });
      this.meta.addTag({ name: 'og:site_name', content: 'Uretao' });
      this.title.setTitle(res[sitename + '.title']);
      //originally Facebook tags
      //site specific
      //Google+
      this.meta.addTag({ name: 'name', content: res[sitename + '.title'] });
      this.meta.addTag({
        name: 'description',
        content: res[sitename + '.description'],
      });
      //Facebook
      this.meta.addTag({ name: 'og:title', content: res[sitename + '.title'] });
      this.meta.addTag({
        name: 'og:description',
        content: res[sitename + '.description'],
      });
      this.meta.addTag({ name: 'og:type', content: res[sitename + '.type'] });
      this.meta.addTag({ name: 'og:url', content: res[sitename + '.url'] });
      //TODO this.meta.addTag({ name: 'og:locale', content: 'en_US' })
      //Twitter
      this.meta.addTag({
        name: 'twitter:card',
        content: res[sitename + '.card'],
      });
      this.meta.addTag({
        name: 'twitter:title',
        content: res[sitename + '.title'],
      });
      this.meta.addTag({
        name: 'twitter:description',
        content: res[sitename + '.description'],
      });
    });
  }

  addAuctionTags(lang, auction) {


    let sitename = 'seo.' + 'auction';
    let keys = [
      sitename + '.type',
      sitename + '.description1',
      sitename + '.description2',
      sitename + '.card',
    ];



    //build auction url
    let link =
      'https://www.uretao.com' +
      '/auction/' +
      auction.auction_id +
      '/' +
      auction.title +
      '/';

    this.translate.get(keys).subscribe((res: any) => {
 
      //general
      this.meta.addTag({ name: 'author', content: 'Uretao' });
      //TODO update sitemap url
      this.meta.addTag({
        name: 'sitemap',
        type: 'application/xml',
        content: 'https://uretao.com/sitemap.xml',
      });
      this.meta.addTag({ name: 'googlebot', content: 'index/follow' });
      this.meta.addTag({ name: 'theme-color', content: '#369ad5' });
      this.meta.addTag({
        name: 'msapplication-navbutton-color',
        content: '#d66b40',
      });
      this.meta.addTag({
        name: 'apple-mobile-web-app-status-bar-style',
        content: '#d66b40',
      });
      this.meta.addTag({ name: 'og:determiner', content: 'Uretao' });
      this.meta.addTag({ name: 'og:site_name', content: 'Uretao' });
      this.title.setTitle(auction.title);
      //originally Facebook tags
      //site specific
      //Google+
      this.meta.addTag({ name: 'name', content: auction.title });

      if (auction.description) {
        let description = auction.description.substring(0, 100);
        this.meta.addTag({ name: 'description', content: description });
        this.meta.addTag({ name: 'og:description', content: description });
        this.meta.addTag({ name: 'twitter:description', content: description });
      } else {
        this.meta.addTag({
          name: 'description',
          content:
            res['seo.auction.description1'] +
            auction.title +
            res['seo.auction.description2'],
        });
        this.meta.addTag({
          name: 'og:description',
          content:
            res['seo.auction.description1'] +
            auction.title +
            res['seo.auction.description2'],
        });
        this.meta.addTag({
          name: 'twitter:description',
          content:
            res['seo.auction.description1'] +
            auction.title +
            res['seo.auction.description2'],
        });
      }

      //Facebook
      this.meta.addTag({ name: 'og:title', content: auction.title });
      this.meta.addTag({ name: 'og:type', content: res['seo.auction.type'] });
      this.meta.addTag({ name: 'og:url', content: link });
      this.meta.addTag({ name: 'og:image', content: auction.url_tb });
      this.meta.addTag({ name: 'og:image:alt', content: auction.title });
      //TODO this.meta.addTag({ name: 'og:locale', content: 'en_US' })
      //Twitter
      this.meta.addTag({
        name: 'twitter:card',
        content: res['seo.auction.card'],
      });
      this.meta.addTag({ name: 'twitter:image', content: auction.url_tb });
      this.meta.addTag({ name: 'twitter:title', content: auction.title });
    });
  }

  updateAuctionTags(lang, auction) {

    let sitename = 'seo.' + 'auction';
    let keys = [
      sitename + '.title',
      sitename + '.description',
      sitename + '.type',
      sitename + '.url',
      sitename + '.image',
      sitename + '.card',
      sitename + '.keywords',
    ];

    //build auction url
    let link =
      'https://www.uretao.com' +
      '/auction/' +
      auction.auction_id +
      '/' +
      auction.title +
      '/';

    this.translate.get(keys).subscribe((res: any) => {
      //general
      this.meta.updateTag({ name: 'author', content: 'Uretao' });
      //TODO update sitemap url
      this.meta.updateTag({
        name: 'sitemap',
        type: 'application/xml',
        content: 'https://uretao.com/sitemap.xml',
      });
      this.meta.updateTag({ name: 'googlebot', content: 'index/follow' });
      this.meta.updateTag({ name: 'theme-color', content: '#369ad5' });
      this.meta.updateTag({
        name: 'msapplication-navbutton-color',
        content: '#d66b40',
      });
      this.meta.updateTag({
        name: 'apple-mobile-web-app-status-bar-style',
        content: '#d66b40',
      });
      this.meta.updateTag({ name: 'og:determiner', content: 'Uretao' });
      this.meta.updateTag({ name: 'og:site_name', content: 'Uretao' });
      this.title.setTitle(auction.title);
      //originally Facebook tags
      //site specific
      //Google+
      this.meta.updateTag({ name: 'name', content: auction.title });

      if (auction.description) {
        let description = auction.description.substring(0, 100);
        this.meta.updateTag({ name: 'description', content: description });
        this.meta.updateTag({
          name: 'og:description',
          content: description,
        });
        this.meta.updateTag({
          name: 'twitter:description',
          content: description,
        });
      } else {
        this.meta.updateTag({
          name: 'description',
          content: res[sitename + '.description1'] + auction.title + res[sitename + '.description2'],
        });
        this.meta.updateTag({
          name: 'og:description',
          content: res[sitename + '.description1'] + auction.title + res[sitename + '.description2'],
        });
        this.meta.updateTag({
          name: 'twitter:description',
          content: res[sitename + '.description1'] + auction.title + res[sitename + '.description2'],
        });
      }

      //Facebook
      this.meta.updateTag({ name: 'og:title', content: auction.title});
      this.meta.updateTag({ name: 'og:type', content: res[sitename + '.type'] });
      this.meta.updateTag({ name: 'og:url', content: link });
      this.meta.updateTag({ name: 'og:image', content: auction.url_tb });
      this.meta.updateTag({ name: 'og:image:alt', content: res[sitename + '.title'] });
      //TODO this.meta.addTag({ name: 'og:locale', content: 'en_US' })
      //Twitter
      this.meta.updateTag({ name: 'twitter:card', content: res[sitename + '.card'] });
      this.meta.updateTag({ name: 'twitter:image', content: auction.url_tb });
      this.meta.updateTag({ name: 'twitter:title', content: auction.title });
    });
  }

  updateTags(sitename) {
    
    sitename = 'seo.' + sitename;
    let keys = [
      sitename + '.title',
      sitename + '.description',
      sitename + '.type',
      sitename + '.url',
      sitename + '.image',
      sitename + '.card',
      sitename + '.keywords',
    ];

    this.translate.get(keys).subscribe((res: any) => {
 
      //general
      this.meta.updateTag({ name: 'author', content: 'Uretao' });
      //TODO update sitemap url
      this.meta.updateTag({
        name: 'sitemap',
        type: 'application/xml',
        content: 'https://uretao.com/seo/sitemap.xml',
      });
      //images 
      this.meta.updateTag({ name: 'images', content: 'https://www.uretao.com/assets/img/logos/logo_small.jpg' });
      this.meta.updateTag({ name: 'og:images', content: 'https://www.uretao.com/assets/img/logos/logo_small.jpg' });
      this.meta.updateTag({ name: 'twitter:images', content: 'https://www.uretao.com/assets/img/logos/logo_small.jpg' });


      this.meta.updateTag({ name: 'googlebot', content: 'index/follow' });
      this.meta.updateTag({ name: 'theme-color', content: '#369ad5' });
      this.meta.updateTag({
        name: 'msapplication-navbutton-color',
        content: '#d66b40',
      });
      this.meta.updateTag({
        name: 'apple-mobile-web-app-status-bar-style',
        content: '#d66b40',
      });
      this.title.setTitle(res[sitename + '.title']);
      //originally Facebook tags
      //site specific
      //Google+
      this.meta.updateTag({ name: 'name', content: res[sitename + '.title'] });
      this.meta.updateTag({
        name: 'description',
        content: res[sitename + '.description'],
      });
      //Facebook
      this.meta.updateTag({
        name: 'og:title',
        content: res[sitename + '.title'],
      });
      this.meta.updateTag({
        name: 'og:description',
        content: res[sitename + '.description'],
      });
      this.meta.updateTag({
        name: 'og:type',
        content: res[sitename + '.type'],
      });
      this.meta.updateTag({ name: 'og:url', content: res[sitename + '.url'] });
      //TODO this.meta.addTag({ name: 'og:locale', content: 'en_US' })
      //Twitter
      this.meta.updateTag({
        name: 'twitter:card',
        content: res[sitename + '.card'],
      });
      this.meta.updateTag({
        name: 'twitter:title',
        content: res[sitename + '.title'],
      });
      this.meta.updateTag({
        name: 'twitter:description',
        content: res[sitename + '.description'],
      });
    });
  }

  //add & update for blog
  //add & update for auction

  backlog() {
    this.title.setTitle('ABOUT US SEO TEST');
    this.meta.addTag({
      name: 'description',
      content: 'FIRST TEST DESCRIPTION',
    });
  }
}
