import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../service/http/authentication.service';
import { Account } from '../../../classes/account';
import { Subject, takeUntil } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { ChatService } from '../../../service/http/chat.service';

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.scss'],
})
export class AccountHeaderComponent implements OnInit, OnDestroy {
  constructor(
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private chatService: ChatService,
    private router: Router,
    @Optional()
    @Inject(REQUEST)
    private request: Request,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  languages;
  selectedLang;
  loggedIn: false;
  account: Account;

  logout() {
    if (this.account) {
      this.stopNotificationsTimer();
      this.authenticationService.logout(this.account).subscribe({
        next: (data) => {
          this.router.navigate(['/']);
        },
        error: (error) => {},
      });
    }
  }

  setTransLanguage(lang) {
    this.translate.use(lang);
    this.translate.setDefaultLang(lang);
    this.getSelectedLang(lang);
  }

  getLanguages() {
    this.languages = this.translate.getLangs();
  }

  //get used language
  getSelectedLang(lang) {
    this.selectedLang = lang;
  }

  error;

  getNotifications() {
    //get conversations
    return this.chatService.getNotifications(this.account.id);
  }

  setAmountOfNotifications(notifications) {
    if (notifications.length < 99 && notifications.length != 0) {
      this.amountOfNotifications = notifications.length;
    } else if (notifications.length >= 99) {
      this.amountOfNotifications = 99;
    } else if (notifications.length == 0) {
      this.amountOfNotifications = null;
    } else {
      this.amountOfNotifications = null;
    }
  }

  amountOfNotifications = null;
  private notificationsTokenTimeout;

  private startNotificationsTimer() {
    // parse json object from base64 encoded jwt token
    //const jwtToken = JSON.parse(atob(this.userValue.token.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    //create refreshTokenRequest

    this.notificationsTokenTimeout = setTimeout(
      () =>
        this.getNotifications().subscribe({
          next: (notifications) => {
            if (this.account) {
              this.setAmountOfNotifications(notifications);
              this.startNotificationsTimer();
            } else {
              this.stopNotificationsTimer();
            }
          },
          error: (error) => {
            if (error.status == 401) {
              this.stopNotificationsTimer();
            }
          },
        }),
      //30 Seconds
      30000
    );
  }

  countNotifications() {}

  private stopNotificationsTimer() {
    clearTimeout(this.notificationsTokenTimeout);
  }

  notificationsArray;

  private ngUnsubscribe = new Subject();
  ngOnInit() {
    this.authenticationService.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((x) => (this.account = x));

    this.chatService.notifications
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((x) => (this.notificationsArray = x));

    //get conversations
    this.chatService.getNotifications(this.account.id).subscribe({
      next: (notifications) => {
        this.setAmountOfNotifications(notifications);
        this.startNotificationsTimer();
      },
      error: (error) => {
        this.error = error;
      },
    });

    this.getBrowserLang();
    this.getLanguages();

    //TODO get notifications and if necessary start timer
  }

  getLang(): string {
    let lang: string;
    if (isPlatformBrowser(this.platformId)) {
      lang = this.translate.getBrowserLang();
    } else {
      lang = (this.request.headers['accept-language'] || '').substring(0, 2);
    }
    return lang;
  }

  openMessage(notification) {
    //TODO open notifications
    //Switch to messages overview and immediately select the desired conversation
    //Use params and if no params, just get all conversations and load default position 0
    this.router.navigate(['/account/chat'], {
      state: {
        conversation_id: notification.conversation_id,
      },
    });
  }

  openMessageMobile(notification) {
    //TODO open notifications
    //Switch to messages overview and immediately select the desired conversation
    //Use params and if no params, just get all conversations and load default position 0
    this.router.navigate(['/account/chat/messages'], {
      state: {
        conversation_id: notification.conversation_id,
        recipient_id: notification.id
      },
    });
  }

  getBrowserLang() {
    //this.selectedLang = this.translate.getBrowserLang();
    this.selectedLang = this.getLang();
    this.translate.use(
      this.selectedLang.match(/de|en/) ? this.selectedLang : 'en'
    );
  }

  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.stopNotificationsTimer();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
