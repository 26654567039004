import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';

import { CategorieComponent } from './auction/categorie/categorie.component';
import { AuthGuard } from './helpers/auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

//import { ShippingAddressComponent } from './shipping-address/shipping-address.component'
const routes: Routes = [
  { path: '', component: CategorieComponent },
  {
    path: 'register',
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterModule),
  },

  {
    path: 'termsofuse',
    loadChildren: () =>
      import('./legal/tos/tos.module').then((m) => m.TosModule),
  },
  {
    path: 'cookie-policy',
    loadChildren: () =>
      import('./legal/cookie-policy/cookie-policy.module').then(
        (m) => m.CookiePolicyModule
      ),
  },
  {
    path: 'impressum',
    loadChildren: () =>
      import('./legal/impressum/impressum.module').then(
        (m) => m.ImpressumModule
      ),
  },
  {
    path: 'roadmap',
    loadChildren: () =>
      import('./roadmap/roadmap.module').then((m) => m.RoadmapModule),
  },
  {
    path: 'offline',
    loadChildren: () =>
      import('./offline/offline.module').then((m) => m.OfflineModule),
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./legal/privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  // { path: 'checkout', component: ShippingAddressComponent },
  {
    path: 'password-code',
    loadChildren: () =>
      import(
        './login/password-forgotten/password-code/password-code.module'
      ).then((m) => m.PasswordCodeModule),
  },
  {
    path: 'auction/approve',
    loadChildren: () =>
      import('./request/approve/approve.module').then((m) => m.ApproveModule),
  },
  {
    path: 'auction/delete',
    loadChildren: () =>
      import('./request/delete/delete.module').then((m) => m.DeleteModule),
  },
  {
    path: 'auction/reject',
    loadChildren: () =>
      import('./request/reject/reject.module').then((m) => m.RejectModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auction/approve',
    loadChildren: () =>
      import('./request/approve/approve.module').then((m) => m.ApproveModule),
  },
  {
    path: 'auction/delete',
    loadChildren: () =>
      import('./request/delete/delete.module').then((m) => m.DeleteModule),
  },
  {
    path: 'auction/reject',
    loadChildren: () =>
      import('./request/reject/reject.module').then((m) => m.RejectModule),
  },
  {
    path: 'account/chat',
    loadChildren: () =>
      import('./account/chat/chat.module').then((m) => m.ChatModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'account/chat/messages',
    loadChildren: () =>
      import(
        './account/chat/chat-mobile/messages-mobile/messages-mobile.module'
      ).then((m) => m.MessagesMobileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./about-us/about-us.module').then((m) => m.AboutUsModule),
  },

  {
    path: 'account/contact',
    loadChildren: () =>
      import('./account/contact/contact.module').then((m) => m.ContactModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'account/auction-overview',
    loadChildren: () =>
      import('./account/auction-overview/auction-overview.module').then(
        (m) => m.AuctionOverviewModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'offer',
    loadChildren: () =>
      import('./offer/offer.module').then((m) => m.OfferModule),
  },
  /* Out of scope, as long as no payment
  {
    path: 'account/order-history',
    component: OrderHistoryComponent,
    canActivate: [AuthGuard],
  },
  */
  //Sell games or tgc. Nested child route, get categorie via URL parameters
  {
    path: 'auction/categories/sell/:categorie',
    loadChildren: () =>
      import('./auction/sell/sell.module').then((m) => m.SellModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auction/sell',
    loadChildren: () =>
      import('./auction/sell/sell-overview/sell-overview.module').then(
        (m) => m.SellOverviewModule
      ),
    canActivate: [AuthGuard],
  },
  /*
  {
    path: 'order/confirmation',
    component: OrderConfirmationComponent,
    canActivate: [AuthGuard],
  },
  */
  {
    path: 'auction/categories',
    loadChildren: () =>
      import('./auction/categorie/categorie.module').then(
        (m) => m.CategorieModule
      ),
  },
  {
    path: 'verification',
    loadChildren: () =>
      import('./email-verification/email-verification.module').then(
        (m) => m.EmailVerificationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'news',
    loadChildren: () =>
      import('./blog/blog-view/blog-view.module').then((m) => m.BlogViewModule),
  },
  {
    path: 'news/:date',
    loadChildren: () =>
      import('./blog/blog-post-view/blog-post-view.module').then(
        (m) => m.BlogPostViewModule
      ),
  },
  {
    path: 'restricted',
    loadChildren: () =>
      import('./legal/country-restricted/country-restricted.module').then(
        (m) => m.CountryRestrictedModule
      ),
  },
  {
    path: 'auction/edit',
    loadChildren: () =>
      import('./auction/edit/edit.module').then((m) => m.EditModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auction/:auction_id',
    loadChildren: () =>
      import('./auction/auction.module').then((m) => m.AuctionModule),
  },
  {
    path: 'search/:search',
    loadChildren: () =>
      import('./search-results/search-results.module').then(
        (m) => m.SearchResultsModule
      ),
  },
  { path: 'order/confirmation', loadChildren: () => import('./order/order-confirmation/order-confirmation.module').then(m => m.OrderConfirmationModule) },
  /* Out of scope, as long as no payment
  { path: 'order', component: OrderComponent, canActivate: [AuthGuard] },
  */
  /*
  {
    path: 'order/renew',
    component: OrderRenewComponent,
    canActivate: [AuthGuard],
  },
  */
  {
    path: 'auction/categories/buy/:categorie',
    loadChildren: () =>
      import('./auction/buy/buy.module').then((m) => m.BuyModule),
  },
  {
    path: 'password-forgotten',
    loadChildren: () =>
      import('./login/password-forgotten/password-forgotten.module').then(
        (m) => m.PasswordForgottenModule
      ),
  },
  {
    path: 'cookie-policy',
    loadChildren: () =>
      import('./legal/cookie-policy/cookie-policy.module').then(
        (m) => m.CookiePolicyModule
      ),
  },
  {
    path: 'impressum',
    loadChildren: () =>
      import('./legal/impressum/impressum.module').then(
        (m) => m.ImpressumModule
      ),
  },
  {
    path: 'roadmap',
    loadChildren: () =>
      import('./roadmap/roadmap.module').then((m) => m.RoadmapModule),
  },
  {
    path: 'offline',
    loadChildren: () =>
      import('./offline/offline.module').then((m) => m.OfflineModule),
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./legal/privacy/privacy.module').then((m) => m.PrivacyModule),
  },

  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabledBlocking',
    }),
    MarkdownModule.forRoot(),
    MarkdownModule.forChild(),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
