<!-- menu for large resolutions here -->
<!-- does exist, login not required -->
<!-- does exist, login not required -->
<div fxShow fxHide.lt-sm>
  <div class="d-flex justify-content-center">
    <div>
      <a
        class="lead account header-text nav-item nav-link active"
        title=" {{ 'header.login_dropdown.register' | translate }}"
        routerLink="/"
        title="Categories"
      >
        {{ "header.categories" | translate }}
      </a>
    </div>
    <div>
      <a
        class="lead account header-text nav-item nav-link active"
        routerLink="auction/sell"
        title="Sell"
      >
        {{ "header.sell" | translate }}
      </a>
    </div>
    <div>
      <a
        class="lead account header-text nav-item nav-link active"
        routerLink="/news"
        title="News"
      >
        {{ "header.news" | translate }}
      </a>
    </div>
    <div>
      <a
        class="lead account header-text nav-item nav-link active"
        routerLink="/offer"
        title="Purchase"
      >
        {{ "header.offer" | translate }}
      </a>
    </div>
    <div>
      <a
        [matMenuTriggerFor]="aboutus"
        class="lead account header-text nav-item nav-link active"
        title="About us"
      >
      {{ "header.aboutus" | translate }}
      </a>

      <mat-menu #aboutus="matMenu">
        <button
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          routerLink="/about-us"
          title="About us"
        >
          {{ "header.aboutus" | translate }}
        </button>
        <button
          mat-menu-item
          class="lead header-text"
          style="outline: none"
          routerLink="/roadmap"
          title="Roadmap"
        >
          {{ "header.roadmap" | translate }}
        </button>
      </mat-menu>
    </div>
  </div>
</div>