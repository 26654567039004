import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { first } from 'rxjs/operators'

import { AuthenticationService } from '../service/http/authentication.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService
  ) {}
  error = '';
  code;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //const user = this.authenticationService.userValue;


    // check is user exists in cookie
    if (this.cookieService.check('user')) {
      // check if refreshToken expired

      if (
        this.cookieService.check('exp') &&
        +this.cookieService.get('exp') * 1000 > Date.now()
      ) {
        //to page
        //check if userValue is null

        if (this.authenticationService.userValue) {

          //check if user email has been verified
          
          if (this.authenticationService.userValue.verified) {
            return true;
          } else {
     
            if (state.url == '/verification') {
              return true;
            } else {
        
              return this.router.parseUrl('/verification');
            }
          }
        } else {
          //do silent login
      
          this.authenticationService
            .silentLogin(this.cookieService.get('user'))
            .pipe(first())
            .subscribe({
              next: (data) => {

                if (this.authenticationService.userValue.verified) {
        
                  return true;
                } else {
                  return this.router.parseUrl('/verification');
                }
              },
              error: (error) => {
                this.error = error;
                return false;
              },
            });
        }
      } else {
        this.authenticationService.logoutManual()
        this.router.navigate(['/login']);
        return false;
      }
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
