import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { Account } from '../../classes/account'
import { AccountRequest } from '../../interface/account-request'
import { LoginRequest } from '../../interface/login-request'

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //private userSubject = new BehaviorSubject<Account>(null);
  private userSubject: BehaviorSubject<Account> = new BehaviorSubject<Account>(
    null
  );
  public user: Observable<Account> = this.userSubject.asObservable();

  //behaviour subject for odersd
  private ordersSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public orders: Observable<any> = this.ordersSubject.asObservable();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    //this.userSubject = new BehaviorSubject<Account>(null);
  }

  public get userValue(): Account {
    return this.userSubject.value;
  }

  updateContacts(contacts) {
    return this.http.put<any>(
      `${environment.apiUrl}/user/contacts/update`,
      contacts,
      {
        withCredentials: true,
      }
    );
  }

  getContactInfoEnabled(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/user/contacts/enabled`, {
      withCredentials: true,
      params: {
        id: id,
      },
    });
  }

  getContactInfo(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/user/contacts`, {
      withCredentials: true,
      params: {
        id: id,
      },
    });
  }

  checkEmailRegister(email: string) {
    return this.http.get<any>(`${environment.apiUrl}/user/check/email`, {
      withCredentials: true,
      params: {
        email: email,
      },
    });
  }

  checkUsername(username: string) {
    return this.http.get<any>(`${environment.apiUrl}/user/check/username`, {
      withCredentials: true,
      params: {
        username: username,
      },
    });
  }

  checkTemporaryEmail(email: string) {
    return this.http.get<any>(
      `https://open.kickbox.com/v1/disposable/` + email
    );
  }

  checkRefreshTokenExpired(email: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/login`, email, {
        withCredentials: true,
      })
      .pipe(
        map((user) => {
          user as Account;
          this.userSubject.next(user);
          //add user to cookie for tabs
          this.cookieService.set(
            'user',
            user.email,
            365,
            '/',
            environment.domain,
            true
          );
          this.startRefreshTokenTimer(user.exp);
          return user;
        })
      );
  }

  /*
  requestNewMail(email, newMail) {
    return this.http.post<any>(
      `${environment.apiUrl}/user/verify/email`,
      { email, newMail },
      {
        withCredentials: true,
      }
    );
  }
  */

  cancelVerificationRequest(email) {
    return this.http
      .delete<any>(`${environment.apiUrl}/user/verify/request`, {
        withCredentials: true,
        params: {
          email: email,
        },
      })
      .pipe(
        map((user) => {
          this.userSubject.next(user);
          //add user to cookie for tabs
          this.cookieService.set(
            'user',
            user.email,
            365,
            '/',
            environment.domain,
            true
          );
          this.cookieService.set(
            'exp',
            user.exp,
            365,
            '/',
            environment.domain,
            true
          );
          this.startRefreshTokenTimer(user.exp);
          return user;
        })
      );
  }

  //input: email, key, nonce
  checkEmail(email: string) {
    return this.http.get<any>(`${environment.apiUrl}/user/verify/email`, {
      withCredentials: true,
      params: {
        email: email,
      },
    });
  }

  //input: email, key, nonce
  requestNewPassword(passwordRequest) {
    return this.http.post<any>(
      `${environment.apiUrl}/user/verify/password`,
      passwordRequest,
      {
        withCredentials: true,
      }
    );
  }

  //input: email, output: nothing to frontend
  requestNewPasswordForgotten(email: string) {
    let cMail = { email: email };

    return this.http.post<any>(
      `${environment.apiUrl}/user/verify/forgotten`,
      cMail,
      {
        withCredentials: true,
      }
    );
  }

  //input: email, key, nonce
  requestNewVerificationCode(email: string) {
    return this.http.post<any>(`${environment.apiUrl}/user/verify/new`, email, {
      withCredentials: true,
    });
  }

  verify(email: string, code: number) {
    return this.http.delete<any>(`${environment.apiUrl}/user/verify`, {
      withCredentials: true,
      params: {
        email: email,
        code: code.toString(),
      },
    });
  }

  verifyType(email: string, code: number, type: string) {
    return this.http.delete<any>(`${environment.apiUrl}/user/verify`, {
      withCredentials: true,
      params: {
        email: email,
        code: code.toString(),
        type: type,
      },
    });
  }

  //get only active orders sorted
  getOrdersByOrder(
    pageLength: number,
    start: number,
    sortOption: string,
    order: string,
    mainCurrency: string
  ) {
    return this.http
      .get<any>(`${environment.apiUrl}/user/order/history`, {
        withCredentials: true,
        params: {
          pageLength: pageLength.toString(),
          start: start.toString(),
          currency: mainCurrency,
          sortOption: sortOption,
          order: order,
        },
      })
      .pipe(
        map((orders) => {
          //build URL structure for each image

          this.ordersSubject.next(orders);
          return orders;
        })
      );
  }

  verifyNewPassword(changePasswordRequest) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/user/verify/password/change`,
        changePasswordRequest,
        {
          withCredentials: true,
        }
      )
      .pipe(
        map((user) => {
          //TODO handle errors?

          this.userSubject.next(user);
          //add user to cookie for tabs
          this.cookieService.set(
            'user',
            user.email,
            365,
            '/',
            environment.domain,
            true
          );
          this.cookieService.set(
            'exp',
            user.exp,
            365,
            '/',
            environment.domain,
            true
          );
          this.startRefreshTokenTimer(user.exp);
          return user;
        })
      );
  }

  checkVerificationType(email: string) {
    return this.http.get<any>(`${environment.apiUrl}/user/verify/checkType`, {
      withCredentials: true,
      params: {
        email: email,
      },
    });
  }
  
  getUser(id: Number) {
    return this.http.get<any>(`${environment.apiUrl}/user`, {
      withCredentials: true,
      params: {
        id: id.toString(),
      },
    });
  }

  login(loginRequest: LoginRequest) {
    return this.http
      .post<any>(`${environment.apiUrl}/login`, loginRequest, {
        withCredentials: true,
      })
      .pipe(
        map((user) => {
          this.userSubject.next(user);
          //add user to cookie for tabs
          this.cookieService.set(
            'user',
            user.email,
            365,
            '/',
            environment.domain,
            true
          );
          this.cookieService.set(
            'exp',
            user.exp,
            365,
            '/',
            environment.domain,
            true
          );
          this.startRefreshTokenTimer(user.exp);
          return user;
        })
      );
  }

  requestNewVerifyCode(email: string) {
    return this.http.get<any>(`${environment.apiUrl}/user/verify/new`, {
      withCredentials: true,
      params: {
        email: email,
      },
    });
  }

  silentLogin(email: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/silentLogin`,
        { email },
        { withCredentials: true }
      )
      .pipe(
        map((user) => {
          this.userSubject.next(user);
          //add user to cookie for tabs
          this.cookieService.set(
            'user',
            user.email,
            365,
            '/',
            environment.domain,
            true
          );
          this.startRefreshTokenTimer(+this.cookieService.get('exp'));
          return user;
        })
      );
  }

  register(account: AccountRequest): Observable<Account> {
    return this.http
      .post<any>(`${environment.apiUrl}/register`, account, {
        withCredentials: true,
      })
      .pipe(
        map((user) => {
          this.userSubject.next(user);
          //add user to cookie for tabs
          this.cookieService.set(
            'user',
            user.email,
            365,
            '/',
            environment.domain,
            true
          );
          this.cookieService.set(
            'exp',
            user.exp,
            365,
            '/',
            environment.domain,
            true
          );
          this.startRefreshTokenTimer(user.exp);
          return user;
        })
      );
  }

  checkVerification(email: string, code: number) {
    return this.http.get<any>(`${environment.apiUrl}/user/verify/check`, {
      withCredentials: true,
      params: {
        email: email,
        code: code.toString(),
      },
    });
  }

  logoutManual() {
    this.userSubject.next(null);
    this.cookieService.delete('user', '/', environment.domain, true);
    this.cookieService.delete('exp', '/', environment.domain, true);
    this.stopRefreshTokenTimer();
  }

  logout(account: Account) {
    return this.http
      .post<any>(`${environment.apiUrl}/logout`, account, {
        withCredentials: true,
      })
      .pipe(
        map((user) => {
          //add user to cookie for tabs
          this.userSubject.next(null);
          this.cookieService.delete('user', '/', environment.domain, true);
          this.cookieService.delete('exp', '/', environment.domain, true);
          this.stopRefreshTokenTimer();
        })
      );
  }

  refreshAccessToken() {
    return this.http
      .post<any>(
        `${environment.apiUrl}/refreshAccessToken`,
        this.userSubject.value,
        { withCredentials: true }
      )
      .pipe(map((user) => {}));
  }

  refreshRefreshToken() {
    return this.http
      .post<any>(
        `${environment.apiUrl}/refreshRefreshToken`,
        this.userSubject.value,
        { withCredentials: true }
      )
      .pipe(
        map((user) => {
          user as Account;

          this.userSubject.next(user);
          this.cookieService.set(
            'user',
            user.email,
            365,
            '/',
            environment.domain,
            true
          );
          this.cookieService.set(
            'email',
            user.exp,
            365,
            '/',
            environment.domain,
            true
          );
          this.startRefreshTokenTimer(user.exp);
          return user;
        })
      );
  }

  getOrder(order_id: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/order`, {
        withCredentials: true,
        params: {
          order_id: order_id,
        },
      })
      .pipe(
        map((order) => {
          //build URL structure for each image
          return order;
        })
      );
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer(exp: number) {
    // parse json object from base64 encoded jwt token
    //const jwtToken = JSON.parse(atob(this.userValue.token.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    //create refreshTokenRequest

    this.refreshTokenTimeout = setTimeout(
      () => this.refreshRefreshToken().subscribe(),
      timeout
    );
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
