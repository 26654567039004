<!-- TODO footer always at the end of the page -->
<nav
  class="
    container-lg
    navbar navbar-expand-lg navbar-light
    bg-white
    justify-small-reso
  "
>
  <div
    class="w-100"
    fxLayout="row"
    fxLayout.lt-lg="row wrap"
    fxLayout.lt-sm="column"
    fxLayoutGap="10px"
    fxLayoutAlign="center center"
    fxLayoutGap.lt-sm="0px"
  >
    <!-- Email -->
    <a
      class="header-text lead nav-item nav-link active"
      routerLink="/privacy"
      title="Datenschutz"
      >{{ "footer.privacy" | translate }}</a
    >
    <a
      class="header-text lead nav-item nav-link active"
      routerLink="/impressum"
      title="Impressum"
      >{{ "footer.whoWeAre" | translate }}</a
    >
    <a
      class="header-text lead nav-item nav-link active"
      routerLink="/termsofuse"
      title="TermsOfUse"
      >{{ "footer.tos" | translate }}</a
    >
    <a
      class="header-text lead nav-item nav-link active"
      routerLink="/cookie-policy"
      title="Cookie Policy"
      >{{ "footer.cookie_policy" | translate }}</a
    >
    <a
      class="header-text lead nav-item nav-link active"
      routerLink="/"
      title="Kontakt"
      >{{ "footer.contact" | translate }}</a
    >
    <a
      class="header-text lead nav-item nav-link active"
      href="https://www.instagram.com/uretaocom/"
      title="Instagram"
      >{{ "footer.instagram" | translate }}</a
    >
    <a
      class="header-text lead nav-item nav-link active"
      href="https://twitter.com/uretaocom"
      title="Twitter"
      >{{ "footer.twitter" | translate }}</a
    >
  </div>
</nav>
